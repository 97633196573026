import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { HomeService } from './services/home/home.service';

declare const gtag: Function;
declare const fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    public router: Router,
    public homeService: HomeService,) {

    // To change favicon 
    let svgUrl: string = 'assets/new-images/main-logo.svg';
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/svg+xml';
    link.href = svgUrl.toString();
    document.head.appendChild(link);


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // gtag('config', 'UA-226085094-1', { 'page_path': event.urlAfterRedirects });
        fbq('track', 'PageView');
      }
    })

    this.homeService.invokeEvent.subscribe((val: any) => {
      if (val == 'homeMetadata') {
        this.ngOnInit()
      }
    });


  }

  // title = 'hireWalksUser';
  title = 'Find and search jobs in India - Job Portal';

  ngOnInit() {

    this.titleService.setTitle("Find and search jobs in India - Job Portal");

    // this.metaTagService.addTags([
    //   {
    //     name: 'description',
    //     content: 'With HireWalks easily find millions of jobs in India to take your career to the highest level. Join the best job search site to get hired fast.',
    //   },
    //   {
    //     name: 'keywords',
    //     content: ' job search, fresher jobs, career, job vacancies, jobs hiring',
    //   },
    // ]);

    /* this.metaTagService.updateTag({
      name: 'description',
      content: 'With HireWalks easily find millions of jobs in India to take your career to the highest level. Join the best job search site to get hired fast.',
    })
    this.metaTagService.updateTag({
      name: 'keywords',
      content: 'job search, fresher jobs, career, job vacancies, jobs hiring',
    }) */

  }


}
